import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContentContainer from "../../components/contentContainer"
import { getTile } from "../../helpers/getTile"

import * as jobwallStyles from "../../styles/jobwallTiles.module.scss"

class JobWallIndex extends React.Component {
  
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const entry1 = data.entry1.edges[0].node
    const entry2 = data.entry2.edges[0].node
    const entry3 = data.entry3.edges[0].node
    const entry4 = data.entry4.edges[0].node
    const entry5 = data.entry5.edges[0].node
    const entry6 = data.entry6.edges[0].node
    const entry7 = data.entry7.edges[0].node
    const entry8 = data.entry8.edges[0].node
    const entry9 = data.entry9.edges[0].node
    const partnerEventImage = data.partnerEventImage.edges[0].node
    const jobwallSeo = data.jobwallSeo.edges[0].node

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={jobwallSeo.frontmatter.seoTitle || jobwallSeo.frontmatter.title} description={jobwallSeo.frontmatter.seoDescription} />
        <div className="content">
          <ContentContainer>
            <div className={jobwallStyles.desktop}>
              <div className={`columns is-multiline`}>
                <div className="column is-6-desktop is-12-tablet is-12-mobile">
                  <div className="columns is-multiline">
                    <div className="column is-12-desktop is-12-tablet is-12-mobile">
                      {getTile(entry1, 1, partnerEventImage, 1, true, true, true, false)}
                    </div>
                    <div className="column is-6-desktop is-6-tablet is-12-mobile">
                      {getTile(entry2, 2, partnerEventImage, 3, false, true, true, false)}
                    </div>
                    <div className="column is-6-desktop is-6-tablet is-12-mobile">
                      {getTile(entry3, 2, partnerEventImage, 3, false, true, true, false)}
                    </div>
                    <div className="column is-6-desktop is-6-tablet is-12-mobile">
                      {getTile(entry4, 2, partnerEventImage, 3, false, true, true, false)}
                    </div>
                    <div className="column is-6-desktop is-6-tablet is-12-mobile">
                      {getTile(entry5, 2, partnerEventImage, 3, false, true, true, false)}
                    </div>
                  </div>
                </div>
                <div className="column is-6-desktop is-12-tablet is-12-mobile">
                  <div className="columns is-multiline">
                    <div className="column is-6">
                      {getTile(entry6, 2, partnerEventImage, 3, true, false, false, false, false)}
                    </div>
                    <div className="column is-6">
                      <div className="columns is-multiline">
                        <div className="column is-12">
                          {getTile(entry7, 2, partnerEventImage, 3, false, true, true, false, false)}
                        </div>
                        <div className="column is-12">
                          {getTile(entry8, 2, partnerEventImage, 3, false, false, true, false, false)}
                        </div>
                      </div>
                    </div>
                    <div className="column is-12">
                      {getTile(entry9, 1, partnerEventImage, 3, true, false, true, false, false)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={jobwallStyles.mobile}>
              <div className={`columns is-multiline`}>
                <div className="column is-12-desktop is-12-tablet is-12-mobile">
                  {getTile(entry1, 1, partnerEventImage, 1, true, true, true)}
                </div>
                <div className="column is-12-desktop is-12-tablet is-12-mobile">
                  {getTile(entry9, 1, partnerEventImage, 3, true, false, true)}
                </div>
                <div className="column is-12-desktop is-12-tablet is-12-mobile">
                  {getTile(entry6, 2, partnerEventImage, 3, true, false, false)}
                </div>
                <div className="column is-12-desktop is-12-tablet is-12-mobile">
                  {getTile(entry2, 2, partnerEventImage, 3, false, true, true)}
                </div>
                <div className="column is-12-desktop is-12-tablet is-12-mobile">
                {getTile(entry3, 2, partnerEventImage, 3, false, true, true)}
                </div>
                <div className="column is-12-desktop is-12-tablet is-12-mobile">
                  {getTile(entry4, 2, partnerEventImage, 3, false, true, true)}
                </div>
                <div className="column is-12-desktop is-12-tablet is-12-mobile">
                  {getTile(entry5, 2, partnerEventImage, 3, false, true, true)}
                </div>
                <div className="column is-12-desktop is-12-tablet is-12-mobile">
                  {getTile(entry7, 2, partnerEventImage, 3, false, true, true)}
                </div>
                <div className="column is-12-desktop is-12-tablet is-12-mobile">
                  {getTile(entry8, 2, partnerEventImage, 3, false, false, true)}
                </div>
              </div>
            </div>
          </ContentContainer>
        </div>
      </Layout>
    )
  }
}

export default JobWallIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    entry1: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/jobwall/(Kachel1/)/"}}) {
      edges {
        node {
          fields {
            slug
          }
          fileAbsolutePath
          frontmatter {
            title
            filterType
            filterValue
            date
            speaker
            type
            link
            generateLink
            internalLink
            jobIcon
            image {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid
                  originalName
                }
              }
            }
          }
          html
          fields {
            slug
          }
        }
      }
    }
    entry2: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/jobwall/Kachel2/"}}) {
      edges {
        node {
          fields {
            slug
          }
          fileAbsolutePath
          frontmatter {
            title
            filterType
            filterValue
            date
            speaker
            type
            link
            generateLink
            internalLink
            jobIcon
            image {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid
                  originalName
                }
              }
            }
          }
          html
          fields {
            slug
          }
        }
      }
    }
    entry3: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/jobwall/Kachel3/"}}) {
      edges {
        node {
          fields {
            slug
          }
          fileAbsolutePath
          frontmatter {
            title
            filterType
            filterValue
            date
            speaker
            type
            link
            generateLink
            internalLink
            jobIcon
            image {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid
                  originalName
                }
              }
            }
          }
          html
          fields {
            slug
          }
        }
      }
    }
    entry4: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/jobwall/Kachel4/"}}) {
      edges {
        node {
          fields {
            slug
          }
          fileAbsolutePath
          frontmatter {
            title
            filterType
            filterValue
            date
            speaker
            type
            link
            generateLink
            internalLink
            jobIcon
            image {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid
                  originalName
                }
              }
            }
          }
          html
          fields {
            slug
          }
        }
      }
    }
    entry5: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/jobwall/Kachel5/"}}) {
      edges {
        node {
          fields {
            slug
          }
          fileAbsolutePath
          frontmatter {
            title
            filterType
            filterValue
            date
            speaker
            type
            link
            generateLink
            internalLink
            jobIcon
            image {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid
                  originalName
                }
              }
            }
          }
          html
          fields {
            slug
          }
        }
      }
    }
    entry6: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/jobwall/Kachel6/"}}) {
      edges {
        node {
          fields {
            slug
          }
          fileAbsolutePath
          frontmatter {
            title
            filterType
            filterValue
            date
            speaker
            type
            link
            generateLink
            internalLink
            jobIcon
            image {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid
                  originalName
                }
              }
            }
          }
          html
          fields {
            slug
          }
        }
      }
    }
    entry7: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/jobwall/Kachel7/"}}) {
      edges {
        node {
          fields {
            slug
          }
          fileAbsolutePath
          frontmatter {
            title
            filterType
            filterValue
            date
            speaker
            type
            link
            generateLink
            internalLink
            jobIcon
            image {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid
                  originalName
                }
              }
            }
          }
          html
          fields {
            slug
          }
        }
      }
    }
    entry8: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/jobwall/Kachel8/"}}) {
      edges {
        node {
          fields {
            slug
          }
          fileAbsolutePath
          frontmatter {
            title
            filterType
            filterValue
            date
            speaker
            type
            link
            generateLink
            internalLink
            jobIcon
            image {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid
                  originalName
                }
              }
            }
          }
          html
          fields {
            slug
          }
        }
      }
    }
    entry9: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/jobwall/Kachel9/"}}) {
      edges {
        node {
          fields {
            slug
          }
          fileAbsolutePath
          frontmatter {
            title
            filterType
            filterValue
            date
            speaker
            type
            link
            generateLink
            internalLink
            jobIcon
            image {
              childImageSharp {
                fluid(maxWidth: 1800) {
                  ...GatsbyImageSharpFluid
                  originalName
                }
              }
            }
          }
          html
          fields {
            slug
          }
        }
      }
    }
    partnerEventImage: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, absolutePath: {regex: "/assets/partnerEventLogo/"}}) {
      edges {
        node {
          id
          childImageSharp {
              fixed(width: 400) {
                  ...GatsbyImageSharpFixed
                originalName
              }
          }
        }
      }
    }
    jobwallSeo: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/jobwall/index.md/"}}) {
      edges {
        node {
          frontmatter {
            title
            seoTitle
            seoDescription
          }
        }
      }
    }
  }
`