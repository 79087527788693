import React from "react"
import BackgroundImage from "gatsby-background-image"
import * as imageTextTileStyles from "./imageTextTile.module.scss"

function ImageTextTile(props) {
    const {image, heading, subheading, isSmall, isBig, prio} = props 
    return(
        <div className={`${isSmall ? imageTextTileStyles.imageSmall: ""} ${isBig ? imageTextTileStyles.imageBig: ""} ${!isBig && !isSmall ? imageTextTileStyles.imageAuto: ""} ${prio===1 ? imageTextTileStyles.prio1 : ""} ${prio===2 ? imageTextTileStyles.prio2 : ""} ${prio===3 ? imageTextTileStyles.prio3 : ""} ${imageTextTileStyles.imageBox}`}>
            <BackgroundImage
                className={imageTextTileStyles.image}
                fluid={image}>
                <div className={imageTextTileStyles.content}>
                  {heading && prio!==1 &&<h2>{heading}</h2>}
                  {heading && prio===1 &&<h1>{heading}</h1>}
                  {subheading && prio===1 &&<h2>{subheading}</h2>}
                  {subheading && prio!==1 &&<h3>{subheading}</h3>}
                </div>
              </BackgroundImage>
        </div>
    )
}

export default ImageTextTile